import { base_url } from "./Base";

export const register_company = async (body) => {
    return await fetch(`${base_url}company`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"POST",
        body: JSON.stringify(body)
    });
}

export const update_company = async (body) => {
    return await fetch(`${base_url}company`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"PUT",
        body: JSON.stringify(body)
    });
}