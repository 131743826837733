import React, { Component } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';

import { Grid } from '@material-ui/core';
import { getEstablishmentsFromCompany, updateEstablishment, createEstablishment, deleteEstablishment } from '../../Services/Establishment';

const phone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/,')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const cell_phone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/,')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const cep = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,/\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const CreateEstablishment = (props) => {
        return (
            <Dialog
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby="form-dialog-title"
                fullScreen
            >
                <DialogTitle id="form-dialog-title">Novo estabelecimento</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="establishment_name"
                        label="Nome do estabelecimento"
                        type="text"
                        onBlur={props.saveState('name')}
                        fullWidth
                    />
                      <TextField
                        margin="dense"
                        id="cep"
                        label="Cep"
                        type="text"
                        fullWidth
                        onBlur={props.saveState('cep')}
                        InputProps={{
                            inputComponent: cep
                        }}
                       />
                       <TextField
                        margin="dense"
                        id="address"
                        label="Endereço"
                        type="text"
                        onBlur={props.saveState('address')}
                        fullWidth
                       />
                       <TextField
                        margin="dense"
                        id="number_address"
                        label="Número"
                        type="text"
                        onBlur={props.saveState('number_address')}
                        fullWidth
                       />
                        <TextField
                        margin="dense"
                        id="phone"
                        label="Telefone"
                        type="text"
                        fullWidth
                        onBlur={props.saveState('phone')}
                        InputProps={{
                            inputComponent: phone
                        }}
                       />
                        <TextField
                        margin="dense"
                        id="cell_phone"
                        label="Celular"
                        type="text"
                        fullWidth
                        onBlur={props.saveState('cell_phone')}

                        InputProps={{
                            inputComponent: cell_phone
                        }}
                       />
                </DialogContent>
                <DialogActions>
                <Button onClick={props.closeModal}  color="primary">
                    Cancel
                </Button>
                <Button onClick={props.create} color="primary">
                    Criar
                </Button>
                </DialogActions>
          </Dialog>
        )
}

const EditEstablishment = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField 
                    type="text"
                    label="Nome"
                    fullWidth
                    onBlur={props.save("name", props.data.id)}
                    defaultValue={props.data.name}
                />
                 <TextField 
                    type="text"
                    label="Cep"
                    fullWidth
                    onBlur={props.save("cep", props.data.id)}
                    defaultValue={props.data.cep}
                    InputProps={{
                        inputComponent: cep
                    }}
                />
                  <TextField 
                    type="text"
                    label="Complemento"
                    fullWidth
                    onBlur={props.save("complement", props.data.id)}
                    defaultValue={props.data.complement}
                />
                  <TextField 
                    type="text"
                    label="Número"
                    fullWidth
                    onBlur={props.save("number_address", props.data.id)}
                    defaultValue={props.data.number_address}
                    
                />
                 <TextField 
                    type="text"
                    label="Telefone"
                    fullWidth
                    onBlur={props.save("phone", props.data.id)}
                    defaultValue={props.data.phone}
                    InputProps={{
                        inputComponent: phone
                    }}
                />
                 <TextField 
                    type="text"
                    label="Celular"
                    fullWidth
                    onBlur={props.save("cell_phone", props.data.id)}
                    defaultValue={props.data.cell_phone}
                    InputProps={{
                        inputComponent: cell_phone
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default class Establishments extends Component {
  state = {
      establishments: [],
      newEstablishment: {
          name: "",
          cep: "",
          address: "",
          number_address: "",
          phone: "",
          cell_phone: ""
      }
  }
  
  async componentDidMount() {
    let response_establishments = await getEstablishmentsFromCompany(1);
    let establishments = await response_establishments.json();
    this.setState({
        establishments
    })
  }

  saveState = ( name, id ) => (e) => {
    let value = e.target.value;
    
    if(name === "cep" || name === "cell_phone" || name === "phone")
        value = value.replace(/[\s.\-()/]/g, "");

    this.setState({
        establishments: this.state.establishments.map(establishment => {
            if(id === establishment.id) {
                establishment[name] = value;
            }
            return establishment;
        })
    });
  }

  update_establishment = (index) => async () => {
    let establishment = this.state.establishments[index];
    await updateEstablishment(establishment.id_company, establishment.id, establishment);
  }

  changeNewEstablishment = (name) => (e) => {
    let value = e.target.value;
    console.log(value);
    if(name === "cep" || name === "cell_phone" || name === "phone") 
        value = value.replace(/[\s.\-()/]/g, "");

    this.setState(prevState => ({
        newEstablishment: {
            ...prevState.newEstablishment,
            [name]: value
        }
    }));
  }

  createEstablishment = async () => {
      let { id } = JSON.parse(localStorage.getItem("current_company"));

      let response_establishment = await createEstablishment(id, this.state.newEstablishment);
      let json = await response_establishment.json();
      this.props.modal();
      if(response_establishment.status === 201) {
        this.setState(prevState => ({
            establishments: [...prevState.establishments, json]
        }))
      }
  }

  delete_establishment = (index) => async () => {
      let {id , id_company} = this.state.establishments[index];
      let response_establishment = await deleteEstablishment(id_company, id);
      
      if(response_establishment.status === 200) {
          this.setState({
              establishments: this.state.establishments.filter((establishment, key) => {
                console.log(`INDEX SELECIONADO: ${index}, INDEX DO ARRAY: ${key}`);
                if(key !== index) return establishment;
                return null;
              })
          })
      }
  }

  render() {
    return (
        <Grid item xs={12} style={{margin: 10}}>
        {this.state.establishments.map((establishment, index) => 
            <ExpansionPanel key={establishment.id} >
                <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                    <Typography >{establishment.name}</Typography>
                </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <EditEstablishment save={this.saveState} data={establishment} />
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        <Button disabled={this.state.establishments.length === 1} onClick={this.delete_establishment(index)}  size="small" color="secondary">
                            Deletar
                        </Button>
                        <Button onClick={this.update_establishment(index)}  size="small" color="primary">
                            Salvar
                        </Button>
                    </ExpansionPanelActions>
            </ExpansionPanel>
        )}
        <CreateEstablishment create={this.createEstablishment} saveState={this.changeNewEstablishment} open={this.props.open} closeModal={this.props.modal} />
        </Grid>
    );
  }
}
