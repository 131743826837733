export const StaticTime = (time) => {
    let explose_time = time.split(":");
    let time_now = new Date();
    
    if(explose_time.length === 2) {
        time_now.setHours(explose_time[0]);
        time_now.setMinutes(explose_time[1]);
        time_now.setSeconds(0);
    } else {
        time_now.setHours(0);
        time_now.setMinutes(0);
        time_now.setSeconds(0);
    }

    return time_now.toLocaleTimeString();
}