import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { login_facebook } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

const facebookId = process.env.REACT_APP_FACEBOOK_ID
const FacebookIcon = <svg style={{marginRight: 10}} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="24px" height="24px"><linearGradient id="Ld6sqrtcxMyckEl6xeDdMa" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"/><stop offset="1" stop-color="#007ad9"/></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"/><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"/></svg>
const custom_login_button = { 
    cursor: 'pointer',
    display: "inline-flex",
    alignItems: 'center',
    padding: 10 ,
    color: "rgba(0, 0, 0, 0.54)", 
    background: "#fff", 
    borderRadius: "2px",
    border: "1px transparent",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px ,rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
  };
const margin_1em = { marginTop: "1em" };

const Login = (props) =>  {
    const [redirect, setRedirect] = useState(false);
    const login = async (response) => {
      const access_token = response.accessToken;
        const response_api = await login_facebook(access_token);
        if (response_api.status === 200) {
          const { accessToken, company, type, user } = await response_api.json();
          localStorage.setItem("worker_app_token", `Bearer ${accessToken}`);
          localStorage.setItem('current_user', JSON.stringify(user));
          if(company) {
            localStorage.setItem('company_created', true);
            localStorage.setItem('current_company', JSON.stringify(company));
            localStorage.setItem('company_type', JSON.stringify(type));
          }
          setRedirect(true)
        } 
    }

  if (redirect) return <Redirect to="/" />
  return (  
      <Grid>
        <FacebookLogin
          appId={facebookId}
          autoLoad={false}
          callback={login}
          render={renderProps => (
            <button
                onClick={renderProps.onClick}
                style={{...custom_login_button,...margin_1em}}
              >
                  <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Roboto, sans-serif"
                  }}
                  >
                  {FacebookIcon}
                  Fazer login com o Facebook
                  </Grid>
              </button>
          )}
        >

        </FacebookLogin>
         
  </Grid>
  )
}

export default Login;
