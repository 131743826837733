import React, { Component } from 'react';
import { Card, CardHeader, Avatar, IconButton, Icon } from '@material-ui/core';

export default class FixedReservedCard extends Component {

    constructor(props) {
        super(props);
        this.days = ['Domingo','Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

    }

    render() {
        let { name , frequency, dayofweek, start, id } = this.props.reserve;
        return(
            <Card style={{marginTop: 5}}>
                <CardHeader 
                    avatar={
                        <Avatar>S</Avatar>
                    }
                    
                    action={
                        <IconButton onClick={() => {this.props.del(id)}} >
                            <Icon style={{color:"red"}}>
                                close
                            </Icon>
                        </IconButton>
                    }
                    title={name}
                    subheader={`${this.days[dayofweek]}, ${start} - ${frequency} dias`}
                />
            </Card>
        )
    }

}