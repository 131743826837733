import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import MaskedInput from 'react-text-mask';
import { Grid } from '@material-ui/core';
import { register_company } from '../../Services/Company';
import { Redirect } from "react-router-dom";
import UploadThumb from '../UploadThumb/UploadThumb';
import { Validator } from '../../Helpers/Validator';
import { viacep } from '../../Services/Thirdparty';
import Loading from '../Loading/Loading';

const cnpj = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/ , '.',/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
}

const cpf = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const cep = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const phone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/,')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const cellphone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/,')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

export default class CreateCompany extends Component {
    state = {
        logo: '',
        typeCompany: '',
        cnpj: '',
        company: '',
        trade: '',
        cpf: '',
        person_name: '',
        establishment_alias: '',
        cep: '',
        address: '',
        number_address: '',
        complement: '',
        phone: '',
        cellphone: '',
        done: false,
        loading: false,     
    }

    constructor(props) {
        super(props);
        this.validator = new Validator();

    }

    saveState = (name) => (e) => {
        let value = e.target.value;
        if(name === "cnpj" || name === "cpf" || name === "phone" || name === "cep" || name === 'cellphone') {
            value = value.replace(/[\s.\-()/]/g, "");
        }
        console.log(this.state);
        this.setState({
            [name]:value
        })

    }
    
    submit = async (e) => {
        e.preventDefault();
        this.setState({
            loading: !this.state.loading
        });
        let form = e.target;
        let { typeCompany ,cnpj ,company, trade, cpf, person_name, 
            establishment_alias, cep, address, number_address,
            complement, phone, cellphone, logo } = this.state;
        let data = {
            typeCompany,
            establishment_alias,
            cep: cep.replace('-',''),
            address,
            number_address,
            complement,
            phone: phone.replace('(','').replace(')','').trim(),
            cellphone: cellphone.replace('(','').replace(')','').trim(),
            logo
        }

        if(typeCompany === 'C') {
            data.corporation = {
                cnpj: cnpj.split('.').join('').replace('/','').replace('-',''), 
                company,
                trade
            }
        } else {
            data.person = {
                cpf: cpf.split('.').join('').replace('-',''),
                person_name
            }
        }
        let response = await register_company(data);
        let json = await response.json();
        if(response.status === 200 && json.company) {
            form.reset();
            localStorage.setItem('company_created',true);
            localStorage.setItem('current_company', JSON.stringify(json.company));
            localStorage.setItem('company_type', JSON.stringify(json.company_type));
            this.setState({
                typeCompany: '',
                cnpj: '',
                company: '',
                trade: '',
                cpf: '',
                person_name: '',
                establishment_alias: '',
                cep: '',
                address: '',
                number_address: '',
                complement: '',
                phone: '',
                cellphone: '',
                done: true,
                loading: false
            })
        } 


    }

    changeImage = (base64) => {
        this.setState({
            logo: base64
        })
    }

    changeCep = async (e) => {
       let cep = e.target.value;
       cep = cep.replace(/[\s.\-()/]/g, "");
       let response_cep = await viacep(cep);
       let json = await response_cep.json();
       this.setState({
            cep
        })     
       if(response_cep.status === 200) {
        let address = '';
        if(json.logradouro) address = json.logradouro;
        this.setState({
            cep,
            address
        })
       }
    }

    render() {
        if(this.state.loading) return <Loading />
        if(this.state.done) return <Redirect to="/worker" />
        const company = this.state.typeCompany;
        if(company === 'P') {
            this.validator.setValues([
                { value:this.state.person_name, min: 7, required:true },
                { value:this.state.cpf, min: 11, max: 11, required:true },
                { value:this.state.establishment_alias, required: true},
                { value:this.state.cep, min: 8},
                { value:this.state.address, required: true},
                { value:this.state.number_address, required: true}
            ])
        } else if(company === 'C') {
            this.validator.setValues([
                { value:this.state.trade, required:true },
                { value:this.state.cnpj, min: 14, max: 14, required:true },
                { value:this.state.company, required: true},
                { value:this.state.address, required: true},
                { value:this.state.number_address, required: true}
            ])
        }
        return(
            <form onSubmit={this.submit}>
                <Grid container >
                    <UploadThumb logo={this.state.logo} changeImage={this.changeImage} />
                    <Grid item xs={12} style={{margin:10}}>
                        <Paper elevation={1} style={{padding:4}}>
                        <Typography variant="subtitle1">
                            Tipo de cadastro:
                        </Typography>
                        <Grid container justify="center">
                            <FormControl component="fieldset" style={{justifyContent: 'center'}}>
                                <RadioGroup
                                    row
                                    name="company_type"
                                    onChange={this.saveState('typeCompany')}
                                >
                                    <FormControlLabel labelPlacement="bottom" value="P" control={<Radio />} label="Pessoa Física" />
                                    <FormControlLabel labelPlacement="bottom" value="C" control={<Radio />} label="Pessoa Jurídica" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        </Paper>
                    </Grid>

                   {
                       company ? 
                       ( <Grid item xs={12} style={{margin:10}}>
                            <Paper elevation={1} style={{padding:10}}>
                                <Typography variant="subtitle1">
                                    Dados {company === 'C' ? "Pessoa Jurídica" : "Pessoa Física"}:
                                </Typography>
                               { company === 'C' ?
                                   (
                                    <div>
                                        <TextField
                                            id="cjpj"
                                            label="Cnpj"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={this.saveState('cnpj')}
                                            defaultValue={this.state.cnpj}
                                            InputProps={{
                                                inputComponent: cnpj
                                            }}
                                        />
                                        <TextField
                                            id="company"
                                            label="Razão Social"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={this.saveState('company')}
                                            defaultValue={this.state.company}
                                        />
                                        <TextField
                                            id="trade"
                                            label="Nome Fantasia"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={this.saveState('trade')}
                                            defaultValue={this.state.trade}

                                        />
                                    </div> ) : (
                                    <div>
                                         <TextField
                                            id="cpf"
                                            label="Cpf"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={this.saveState('cpf')}
                                            InputProps={{
                                                inputComponent: cpf
                                            }}
                                            defaultValue={this.state.cpf}
                                        />
                                          <TextField
                                            id="person_name"
                                            label="Nome Titular"
                                            margin="dense"
                                            key="person_name"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={this.saveState('person_name')}
                                            defaultValue={this.state.person_name}
                                        />
                                    </div>)
                               }
                            </Paper>
                         </Grid>
                        ) : null
                   }
                   {
                       company ? (
                           <Grid item xs={12} style={{margin:10}}>
                                <Paper elevation={1} style={{padding: 10}}>
                                    <Typography variant="subtitle1">
                                        Dados do Estabelecimento:
                                    </Typography>
                                    <TextField
                                        id="establishment_alias"
                                        label="Nome Estabelecimento"
                                        margin="dense"
                                        fullWidth
                                        autoComplete="off"
                                        onBlur={this.saveState('establishment_alias')}
                                        defaultValue={this.state.establishment_alias}
                                    />
                                      <TextField
                                            id="cep"
                                            label="Cep"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            onBlur={this.changeCep}
                                            defaultValue={this.state.cep}
                                            InputProps={{
                                                inputComponent: cep
                                            }}
                                        />
                                          <TextField
                                                id="address"
                                                label="Endereço"
                                                margin="dense"
                                                fullWidth
                                                value={this.state.address || ''}
                                                disabled
                                            />
                                             <TextField
                                                id="number_address"
                                                label="Número"
                                                margin="dense"
                                                fullWidth
                                                autoComplete="off"
                                                onBlur={this.saveState('number_address')}
                                                defaultValue={this.state.number_address}
                                            />
                                              <TextField
                                                id="complement"
                                                label="Complemento"
                                                margin="dense"
                                                fullWidth
                                                autoComplete="off"
                                                onBlur={this.saveState('complement')}
                                                defaultValue={this.state.complement}
                                            />
                                              <TextField
                                                id="phone"
                                                label="Telefone"
                                                margin="dense"
                                                fullWidth
                                                autoComplete="off"
                                                onBlur={this.saveState('phone')}
                                                defaultValue={this.state.number}
                                                InputProps={{
                                                    inputComponent: phone
                                                }}
                                            />
                                              <TextField
                                                id="cellphone"
                                                label="Celular"
                                                margin="dense"
                                                fullWidth
                                                autoComplete="off"
                                                onBlur={this.saveState('cellphone')}
                                                defaultValue={this.state.cellphone}
                                                InputProps={{
                                                    inputComponent: cellphone
                                                }}
                                            />
                                </Paper>
                           </Grid>
                       ) : null
                   }
                   <Button disabled={!this.validator.check()} type="submit"  style={{margin: 10}} fullWidth variant="contained" size="medium" color="secondary">
                        enviar
                    </Button>
                </Grid>
         </form>
        )
    }
}