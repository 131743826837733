import React, { Component } from 'react';
import { ScheduleAdd } from '../ScheduleConfig/Add/ScheduleAdd';
import Header from '../Header/Header';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class CreateSchedule extends Component {
  render() {
    if(hasPermission()) return <Redirect to="/" />
    return (
        <div>
            <Header title="Criar agenda" backbutton={this.props.history} />
            <ScheduleAdd />
        </div>
    )
  }
}
