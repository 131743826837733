import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Icon from '@material-ui/core/Icon';
import { Grid, Paper, Typography } from '@material-ui/core';

export class ScheduleConfigItem extends Component {
    render() {
      let {worker, interval, id} = this.props;
      return (
        <Grid item xs={12} style={{margin:10}}>
        <Link style={{textDecoration:'none'}} to={`/schedule/${id}/edit`}>
          <Paper style={{padding:"15px"}} elevation={1} >
  
            <Grid style={{color:"#373737"}} container>
             
             <Grid item xs={1}>
                <Icon style={{fontSize: 18, marginTop:"2px"}}> insert_invitation</Icon>
             </Grid>
  
             <Grid style={{marginTop:"1px"}} item xs={7}>
                <Typography>
                  Agenda - {worker}
                </Typography>
             </Grid>
  
             <Grid  item xs={1}>
              <Icon style={{fontSize: 18, marginTop:"2px"}}> schedule</Icon>
             </Grid>
  
             <Grid style={{marginTop:"1px"}} item xs={2}>
                <Typography>
                {interval}
                </Typography>
             </Grid>
  
            </Grid>
  
          </Paper>
          </Link>
        </Grid>   
        
      )
    }
}

