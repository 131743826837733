import React, { Component } from 'react';
import Unlock from '../Unlock/Unlock';
import Header from '../Header/Header';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class UnlockCalendar extends Component {
  render() {
    if(hasPermission()) return <Redirect to="/" />
    return (
        <div>
            <Header title="Desbloqueios" backbutton={this.props.history}/>
            <Unlock {...this.props}/>
        </div>
    )
  }
}
