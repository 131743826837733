import React, { PureComponent } from 'react';

import { updateWorkingTime } from '../../../Services/WorkingTime';
import SelectInterval from '../../SelectIntervals/SelectInterval';
import Icon from '@material-ui/core/Icon';

import IconButton from '@material-ui/core/IconButton';

export default class WorkingTimeItem extends PureComponent {

    constructor(props) {
        super(props);

        let times = [ 
            "00:00","01:00","02:00","03:00","04:00","05:00",
            "06:00","07:00","08:00","09:00","10:00","11:00",
            "12:00","13:00","14:00","15:00","16:00","17:00",
            "18:00","19:00","20:00","21:00","22:00","23:00","23:59"
        ]

        this.times = times;
        this.start = props.working.start.substring(0,5);
        this.end = props.working.end.substring(0,5);
    }

    setWorking = async (time) => {
        const { seid , id} = this.props.working;
        await updateWorkingTime(seid, id, time);
    }

    delete = () => {
        this.props.delete(this.props.working.id);
    }

  render() {
    return (
        <SelectInterval 
            change={this.setWorking}
            start={this.start} 
            end={this.end} 
            times={this.times} >
            <IconButton color="secondary" onClick={this.delete}>
            <Icon >remove</Icon>
          </IconButton>    
        </SelectInterval>
    )
  }
}
