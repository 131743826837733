import React from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText, Typography } from '@material-ui/core';

const ModalEstablishment = (props) => {
    const establishments  = props.establishments ? props.establishments : [];
    return (
        <Dialog
            open={props.open}
            onClose={props.close}
        >
            <DialogTitle>
                Selecione o estabelecimento
            </DialogTitle>
            <List>
                {establishments.map((establishment, index) => {
                    return (
                    <ListItem onClick={() => {props.selectEstablishment(establishment);props.close()}} button key={index}>
                        <ListItemText >
                            <Typography variant="title">
                                {establishment.company_name}
                            </Typography>
                            <Typography >
                                {establishment.name}
                            </Typography>
                            <Typography variant="caption">
                                {establishment.address}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    )
                }
                )}
            </List>
        </Dialog>
    )
}

export default ModalEstablishment;