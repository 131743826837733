import React, { PureComponent } from 'react';

import { getWorkingTimes } from '../../../Services/WorkingTime';
import { Grid } from '@material-ui/core';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import WorkingTimeList from './WorkingTimeList';

export class ScheduleWorkingEdit extends PureComponent {
  
    state = {
        days: [],
        ready: 0
    }

  async componentDidMount() {
      let days = [
          [],[],[],[],[],[],[]
      ];
      let response = await getWorkingTimes(this.props.match.params.id);
      let json = await response.json();
      json.map((working) => {
        if(days[working.day_of_week])
            days[working.day_of_week].push(working);

        return true;
      })

      this.setState({
          days,
          ready:1
      })
  }
  
  render() {
    let seid = this.props.match.params.id;
    if(this.state.ready !== 1) return 'Loading...';
    return (
        <Grid item xs={12} style={{margin:10}}>
            {this.state.days.map((day,index) => {
                let day_week;
                if(index === 0) day_week = "Domingo"
                if(index === 1) day_week = "Segunda-feira"
                if(index === 2) day_week = "Terça-feira"
                if(index === 3) day_week = "Quarta-feira"
                if(index === 4) day_week = "Quinta-feira"
                if(index === 5) day_week = "Sexta-feira"
                if(index === 6) day_week = "Sábado"

                return (
                    <ExpansionPanel key={index}>
                        <ExpansionPanelSummary >
                        <Typography >{day_week}</Typography>
                        </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <WorkingTimeList seid={seid} day_of_week={index} working={day} />
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
            })}
        </Grid>
    )
  }
}
