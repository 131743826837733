import React, { Component } from 'react';
import { Dialog, Icon, TextField, FormControl, FormLabel, 
    RadioGroup, FormControlLabel, Radio, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import {format} from 'date-fns';
import { getCustomers, createCustomer } from '../../Services/Customer';
import { Validator } from '../../Helpers/Validator';
import { scheduling_item } from '../../Services/SchedulingItems';
import Loading from '../Loading/Loading';


class ModalSchedule extends Component  {
    state = {
        id: "",
        id_user: "",
        name: "",
        birthdate: "",
        telephone: "",
        sex: "",
        exist: false,
        isLoading: false
    }

    constructor(props) {
        super(props);
        this.company = JSON.parse(localStorage.getItem('current_company'));
        this.cellphone = (props) => {
            const { inputRef , ...other} = props;
    
            return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={['(',/\d/, /\d/, ')', /\d/ ,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
            />
            )
        }
        this.birthdate = (props) => {
            const { inputRef , ...other} = props;
          
            return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[/\d/, /\d/, '/', /\d/ ,/\d/,'/', /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
            />
            )
          }

         this.validator = new Validator();

         this.validatorAll = new Validator();

    }

    requestCustomer = async (e) => {
        // let tel = e.target.value.replace("(","").replace(")","");
            // tel = tel.trim();
        if(this.state.telephone.length === 11) {
            let response = await getCustomers(this.company.id, this.state.telephone);
            let customer = await response.json();
            
            
            if(customer[0] && !this.state.exist) {
                let {id, id_user, name, birthdate, sex} = customer[0];
                this.setState({
                    id,crid: id, id_user, name, birthdate, sex, exist:true, 
                });
            }
        } 
    }

    changeTelephone = (e) => {
    let telephone = e.target.value.replace("(","").replace(")","");
        telephone = telephone.trim();

        this.setState({
            telephone
        })

        if(telephone.length === 10) {
            this.setState({
                id: '',
                crid: '',
                id_user: '',
                name: '',
                sex: '',
                birthdate: '',
                exist: false
            })
        }
    }

    changeName = (e) => {   
      this.setState({
          name: e.target.value
      })
    }

    changeBirthdate = (e) => {
        let birthdate = e.target.value;
        this.setState({
            birthdate
        })
    }

    changeGender = (e) => {
        this.setState({
            sex: e.target.value
        });
    }

    changeFixed = (e) => {
        this.setState({
            fixed: e.target.value
        });
    }

    closeModal = (status = null) => {
        this.props.close(status);
        if(status === 201 || typeof status === 'object') {
              this.setState({
                id: "",
                sex: "",
                name: "",
                id_user: "",
                telephone:"",
                birthdate: "",
                crid: "",
                fixed: "",
                isLoading: false
            })
        }
    }

    scheduling = async (scheduleid,customerid,date,serviceid,fixed) => {
            let response_schedule_item = await scheduling_item(
                scheduleid,
                customerid,
                date,
                serviceid,
                fixed
            );
        let scheduling = await response_schedule_item.json();
        return {
            status:response_schedule_item.status,
            data: scheduling.message
        };
    }

    scheduleCustomer = async () => {
        this.setState({
            isLoading: true
        });

        let { name, telephone, birthdate, sex} = this.state;
        let { establishmentSchedule } = this.props;
        let div = birthdate.split("/");
            birthdate = `${div[2]}-${div[1]}-${div[0]}`;

        let date = `${this.props.date} ${this.props.schedule}`;
        
        if(this.state.id) {
            let response = await this.scheduling(establishmentSchedule.schedule_id,this.state.crid,date,this.props.service.id,this.state.fixed);
            if(response.status === 201) {
                this.closeModal(response);
            } else if( response.status) {
                this.closeModal(response);
            }
        } else {
            let response_customer = await createCustomer(this.company.id, {
                name,
                telephone,
                sex,
                birthdate
            })

            let customer = await response_customer.json();

            let response = await this.scheduling(establishmentSchedule.schedule_id,customer.pivot.crid,date,this.props.service.id,this.state.fixed);
            if(response.status === 201) {
                this.closeModal(response);
            }
        }
    }

    render() {
        if (this.state.isLoading) return <Loading />;
       this.validator.setValues([
         { value: this.state.telephone.trim(), min:11, required: true}
        ]);
        this.validatorAll.setValues([
         {name: "telephone", value: this.state.telephone.trim(), min:11, required: true},
         {name: "name", value: this.state.name, required: true},
         {name: "nascimento", value: this.state.birthdate.trim(), min:10, required: true},
         {name: "sex", value: this.state.sex, required: true}
        ]);
        return (
         <Dialog
            open={this.props.open}
            onClose={this.closeModal}
            fullScreen
         >
             <AppBar style={{background:"#01BF9B",flexGrow: 1}} >
                <Toolbar>
                    <Icon onClick={this.closeModal}>close</Icon>
                    <Typography style={{flexGrow:1}} variant="subtitle1" color="inherit" noWrap >
                        Agendar Cliente
                    </Typography>
                    <IconButton disabled={!this.validatorAll.check()} color="inherit" onClick={this.scheduleCustomer}>
                        <Icon>done</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{margin:"60px 10px"}}>
            <TextField
                    id="user_telephone_register"
                    label="Data e horário"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    defaultValue={`${format(this.props.date,"dd/MM/yyyy")} ${this.props.schedule}`}
                    disabled={true}
                   
                />
            <TextField
                    id="user_telephone_register"
                    label="Serviço"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    defaultValue={this.props.service.service_name}
                    disabled={true}
                   
                />
            <TextField
                    id="user_telephone_register"
                    label="Celular"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    value={this.state.telephone}
                    onChange={this.changeTelephone}
                    onBlur={this.requestCustomer}
                    InputProps={{
                      inputComponent: this.cellphone
                    }}
                />
                  <TextField
                    id="user_name_register"
                    label="Nome completo"
                    margin="dense"
                    disabled={this.state.exist}
                    fullWidth
                    autoComplete="off"
                    value={this.state.name}
                    onChange={this.changeName}
                    style={{display: this.validator.check() ? '' : 'none'}}

                />
                   <TextField
                    id="user_birthdate_register"
                    label="Data Nascimento"
                    margin="dense"
                    value={this.state.birthdate}
                    disabled={this.state.exist}
                    onChange={this.changeBirthdate}
                    fullWidth
                    autoComplete="off"
                    style={{display: this.validator.check() ? '' : 'none'}}
                    InputProps={{
                      inputComponent: this.birthdate
                    }}
                />
                <FormControl style={{marginTop:"20px"}} component="fieldset">
                  <FormLabel 
                  component="legend"
                  style={{display: this.validator.check() ? '' : 'none'}}
                  
                  >
                    Gênero
                  </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      value={this.state.sex}
                      onChange={this.changeGender}
                      style={{display: this.validator.check() ? '' : 'none'}}
                      

                    >
                      <FormControlLabel value="M" control={<Radio disabled={this.state.exist} />} label="Masculino" />
                      <FormControlLabel value="F" control={<Radio disabled={this.state.exist} />} label="Feminino" />
                    </RadioGroup>
                </FormControl>
                <br />
                <FormControl style={{marginTop:"20px"}} component="fieldset">
                  <FormLabel 
                  component="legend"
                  style={{display: this.validator.check() ? '' : 'none'}}
                  >
                    Horário Reservado
                  </FormLabel>
                    <RadioGroup
                      aria-label="fixed"
                      name="fixed"
                      style={{display: this.validator.check() ? '' : 'none'}}
                      onChange={this.changeFixed}
                      row
                    >
                      <FormControlLabel value="7" control={<Radio />} label="7 dias" />
                    <FormControlLabel value="14" control={<Radio />} label="14 dias" />
                    </RadioGroup>
                </FormControl>
            </div>
            
         </Dialog>
        )
    }
}

export default ModalSchedule;