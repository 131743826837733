import React, { Component } from 'react';
import Header from '../Header/Header';
import Users from '../Settings/Users';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class User extends Component {
  state = {
      open: false
  }

  toggleModal = () => {
    this.setState({
        open: !this.state.open
    })
  }

  render() {
    if(hasPermission()) return <Redirect to="/" />
    const header_buttons = [
        {type: "action", icon_name: "add", callback: this.toggleModal }
    ]
    
    return (
        <>
            <Header buttons={header_buttons} title="Usuários" backbutton={this.props.history} />
            <Users modal={this.toggleModal} open={this.state.open} />
        </>
    );
  }
}
