import React, { useState, useCallback, useRef } from "react";
import { withStyles, Paper, Typography, Grid } from "@material-ui/core";
import { InlineDatePicker } from "material-ui-pickers";
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import {format} from 'date-fns';



function CalendarModal(props) {
  let now = new Date();
  let date = now.setDate(now.getDate() + 7);
  const pickerRef = useRef(null);
  const [selectedDate,setSelectedDate] = useState(new Date());

  const handleDateChange = (e) => {
    setSelectedDate(e);
    props.export(e);
  }
  const openPicker = useCallback(
    e => {
      if (pickerRef.current) {
        pickerRef.current.open(e);
      }
    },
    [pickerRef.current]
  );

  const changeFilter = () => {
    props.toggleFilter(selectedDate);
  }

  const filterService = () => {
    props.filterService();
  }

  const filter = props.filter ? (
    <IconButton  onClick={changeFilter} >
            <Icon style={{color:"#000"}} >
            event_busy
            </Icon>
      </IconButton>
  ) : (
    <IconButton  onClick={changeFilter} >
            <Icon style={{color:"#000"}} >
            event_available
            </Icon>
      </IconButton>
  );

  return (
    <Grid item xs={12}>

    <Paper style={{display: "flex", alignItems: "center", margin: 10}}>
      <IconButton onClick={openPicker}>
          <Icon style={{color:"#000"}} >
              date_range
          </Icon>
      </IconButton>
      <Divider style={{width:1, height:28, margin: 4}} />
      <div style={{display:"none"}}  className="picker">
        <InlineDatePicker
          disablePast
          format="dd/MM/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          ref={pickerRef}
          maxDate={date}
        />
      </div>
      <Grid item xs={3} >
        <Typography>
          {format(selectedDate,"dd/MM/yyyy")}
        </Typography>
      </Grid>
      <Divider style={{width:1, height:28, margin: 4}} />
      <Grid item xs={5}>
        <Typography onClick={filterService}  style={{display: "flex", alignItems: "center"}}>
         {props.name} <Icon fontSize="small">keyboard_arrow_down</Icon>
         </Typography>
      </Grid>

      <Grid item xs={2}>
        {filter}
      
      </Grid>
    </Paper>
    </Grid>

  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
};

export default withStyles(styles)(CalendarModal);
