import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';



class BackButton extends Component {

    render() {
        return (
            <IconButton style={{marginLeft:-12, marginRight:20, color:"#fff"}} onClick={() => this.props.history.goBack()}>
                <Icon >arrow_back</Icon>
            </IconButton>
        )
    }
}

export default BackButton;