import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

const ModalEdit = (props) => {
    return (
        <Dialog
        open={props.open}
        onClose={props.close}
     >
          <DialogTitle>
            Cancelar Agendamento
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="text-modal">
              {props.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Agora não
            </Button>
            <Button onClick={props.action} color="primary">
              Ok
            </Button>
          </DialogActions>
     </Dialog>
    )
}

export default ModalEdit;