import React from 'react';
import {CircularProgress, Grid} from '@material-ui/core';

const Loading = () => (
    <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", background: "rgba(0,0,0,0.6)", position: "absolute", top: 0, left:0 }}
    >
        <CircularProgress disableShrink />
    </Grid>
)

export default Loading;
