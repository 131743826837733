import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import { TextField } from '@material-ui/core';



export default class MaskTextField extends Component {
  constructor(props) {
    super();

  }

  inputMask = (props) => {
    const { inputRef , ...other} = props;
    const { show, mask } = this.props;
      return (
        <MaskedInput
          {...other}
          ref={ref => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={mask()}
          showMask={show}
          placeholderChar={'\u2000'}
        />
      )
  }

  onChange = (e) => {
    this.props.onChange(e);
  }

  render() {
    return (
      <TextField 
        label={this.props.label}
        onChange={this.onChange}
        defaultValue={this.props.defaultValue}
        InputProps={{
          inputComponent: this.inputMask
        }}
      />
    )
  }
}
