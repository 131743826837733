import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import {Grid} from '@material-ui/core';

import { BasePicker, Calendar } from 'material-ui-pickers';
import { getUnlock, createUnlock, deleteUnlock } from '../../Services/UnlockCalendar';
import {format} from 'date-fns';

import Schedule from './Schedule';


export default class Unlock extends Component {
  state = {
    selectedDate: new Date(),
    schedules: [],
    schedule_id: 0,
    error: ""
  }

  async componentDidMount() {
    let { id } = this.props.match.params;
    let {selectedDate} = this.state;
    let date = format(selectedDate, 'yyyy-MM-dd');

    let response = await getUnlock(id,date);
    let schedules = await response.json();
    this.setState({
      schedules,
      schedule_id: id
    })

  }

  handleDateChange = async (selectedDate) => {
    let date = format(selectedDate, 'yyyy-MM-dd');
    let response = await  getUnlock(this.state.schedule_id,date);
    let schedules = await response.json();
    this.setState({
      selectedDate,
      schedules
    });

  }

  changeSchedule =  (e, act) => {
    let {selectedDate} = this.state;
    let date_calendar = format(selectedDate, 'yyyy-MM-dd');

    if(!act) {
      let response = createUnlock(this.state.schedule_id,{
        start: e,
        end: e,
        date_calendar
      });

      response.then(res => {
        if(res.status !== 201) {
          console.log("Oops, não foi possível desbloquear...");
        }
      })
    } else {
      deleteUnlock(this.state.schedule_id,e,{
        date_calendar
      })
    }
    this.setState({
        schedules: this.state.schedules.map(schedule => {
            if(schedule.current === e)
                schedule.active = !schedule.active;
            
            return schedule;
        })
    })
  }

  render() {
    const { selectedDate, schedules } = this.state;
    console.log(selectedDate);
    const free = {
      float: "left",
      fontSize: "9pt",
      color: "#000",
      padding: "2px 10px 2px 10px",
      border: "1px solid #43e055",
      margin: "8px 4px",
      borderRadius: "20px",
      background: "#43e055",
    }

    const locked = {
        float: "left",
        fontSize: "9pt",
        color: "#000",
        padding: "2px 10px 2px 10px",
        border: "1px solid #000",
        margin: "8px 4px",
        borderRadius: "20px",
    }
    return (
    <Grid item xs={12} >
      
            <BasePicker value={selectedDate} onChange={this.handleDateChange} >
                {({
                  date,
                }) => (
                  <div>
                    <div className="picker">
                      <Paper style={{ overflow: 'hidden' }}>
                        <Calendar disablePast date={date} onChange={this.handleDateChange} />
                      </Paper>
                    </div>
                  </div>
                )}
            </BasePicker> 
            <Schedule toggle={this.changeSchedule} 
                      schedules={schedules} 
                      standard={locked}
                      selected={free}
                      />
        </Grid>
    )
  }
}
