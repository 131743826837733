import React, { Component } from 'react';
import {ScheduleList} from '../ScheduleConfig/ScheduleList';
import Header from '../Header/Header';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class Schedule extends Component {
  async componentDidMount() {
  }

  render() {
    if(hasPermission()) return <Redirect to="/" />
    const header_buttons = [
        { type: "link", icon_name: "add", link: "/schedule/add" }
    ]
    return (
        <div>
            <Header buttons={header_buttons} title="Agendas" backbutton={this.props.history} />
            <ScheduleList />
        </div>
    )
  }
}
