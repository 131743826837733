import React, {PureComponent} from 'react';
import { ScheduleConfigItem } from './ScheduleConfigItem';
import { list_schedules } from '../../Services/ScheduleConfig';
import { Grid, Icon, Typography } from '@material-ui/core';
import { authUserEstablishments } from '../../Auth/Auth';
import ModalEstablishment from '../Schedule/ModalEstablishment';
// import db from '../../db';

export class ScheduleList extends PureComponent {
    _isMounted = false;
    state = {
        schedules: [],
        establishments: [],
        openModalEstablishment: false,
        selectedEstablishment: {}
    }

    
    
    async componentDidMount() {
        this._isMounted = true;

        this.setState({
            establishments: [],
            schedules: [] ,
            selectedEstablishment:  {}
        })

        let response_establishments, establishments, selectedEstablishment, establishment_id,
            response_establishment_schedules, schedules, worker;
            worker = JSON.parse(localStorage.getItem('current_user'));

            response_establishments = await authUserEstablishments(worker.id);
            establishments = await response_establishments.json();
            selectedEstablishment = establishments[0];
            
            establishment_id = selectedEstablishment.id;
            response_establishment_schedules = await list_schedules(establishment_id);
            schedules = await response_establishment_schedules.json();
      
        if(this._isMounted) {
            this.setState({
                schedules,
                establishments,
                selectedEstablishment
            })

        }
    }

    selectedEstablishment = async (selectedEstablishment) => {
        let establishment_id = selectedEstablishment.id;
        let response_establishment_schedules = await list_schedules(establishment_id);
        let schedules = await response_establishment_schedules.json();
        this.setState({
            schedules,
            selectedEstablishment
        })
    }

    filterEstablishment = () => {
        this.setState({
            openModalEstablishment: !this.state.openModalEstablishment
        })
    }

    closeModalEstablishment = () => {
        this.setState({
            openModalEstablishment: !this.state.openModalEstablishment
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        const { name , company_name } = this.state.selectedEstablishment; 
        let fname = name ? name : "";
        let fcompany = company_name ? company_name : "";
        return (
            
            <Grid container >
                <Typography onClick={this.filterEstablishment} variant="caption" style={{color:"#000",display: "flex", alignItems: "center", margin:10}}>
                    {`${fcompany} - ${fname}`} <Icon fontSize="small">keyboard_arrow_down</Icon>
                </Typography>
                {this.state.schedules.map(schedule => 
                    <ScheduleConfigItem id={schedule.id_schedule} 
                                        worker={schedule.worker_name} 
                                        interval={schedule.schedule_interval} 
                                        key={schedule.id_schedule}
                                        />
                )}
                <ModalEstablishment 
                    selectEstablishment={this.selectedEstablishment}
                    open={this.state.openModalEstablishment}
                    close={this.closeModalEstablishment}
                    establishments={this.state.establishments}
                />
            </Grid>
        )
    
    }

}