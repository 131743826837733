import { base_url } from "./Base";
export const free_schedule = (scheduleid, date, serviceid = 0) => {
    return fetch(`${base_url}schedule/${scheduleid}/free_schedule?date=${date}&service=${serviceid}`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("worker_app_token")        
      },
      method: "GET"
    });
}

export const get_services = (scheduleid) => {
  return fetch(`${base_url}schedule/${scheduleid}/service`,{
    headers: {
      "Content-Type" : "application/json",
      "Accept" : "application/json",
      "Authorization" : localStorage.getItem("worker_app_token")        
    },
    method: "GET"
  });
}

export const workspace = () => {
  return fetch(`${base_url}workspace`,{
    headers: {
      "Content-Type" : "application/json",
      "Accept" : "application/json",
      "Authorization" : localStorage.getItem("worker_app_token")        
    },
    method: "GET"
  });
}
