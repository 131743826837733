import React from 'react';
import {isAuthenticated , companyCreated , loggout} from './Auth/Auth';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './Components/Pages/Login';
import Register from './Components/Pages/Register';
import Success from './Components/Pages/Success';
import Home from './Components/Pages/Home';
import Password from './Components/Pages/Password';
import Schedule from './Components/Pages/Schedule';
import CreateSchedule from './Components/Pages/CreateSchedule';
import EditSchedule from './Components/Pages/EditSchedule';
import Services from './Components/Pages/Services';
import WorkingTime from './Components/Pages/WorkingTime';
import UnlockCalendar from './Components/Pages/UnlockCalendar';
import FixedReserves from './Components/Pages/FixedReserves';
import CalendarLocks from './Components/Pages/CalendarLocks';
import CreateCompany from './Components/Pages/CreateCompany';
import Profile from './Components/Pages/Profile';
import Settings from './Components/Pages/Settings';
import Establishment from './Components/Pages/Establishment';
import User from './Components/Pages/User';
import CompleteRegister from './Components/Pages/CompleteRegister';
import Verify from './Components/Pages/Verify';
import ForgotPassword from './Components/Pages/ForgotPassword';
import ResetPassword from './Components/Pages/ResetPassword';
    
const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        if(isAuthenticated()) {
            if(!companyCreated()) return <Redirect to={{pathname: '/requirement/company', state: { from: props.location }}} />
            return <Component {...props} />
        }

        return <Redirect to={{ pathname: '/' ,state: { from: props.location }}}/>
    }} />
);

const RequirementsPrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        if(isAuthenticated()) 
            if(!companyCreated()) return <Component {...props} />
    
    return <Redirect to={{ pathname: '/' ,state:{ from: props.location }}}/>
            
    }} />
);

const AuthenticatedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Redirect to={{ pathname: '/worker' ,state:{ from: props.location }}}/>
        ) : (
            <Component {...props} />
        )
    )} />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <AuthenticatedRoute exact path="/" component={Login} />
            <AuthenticatedRoute path="/register" component={Register} />
            <AuthenticatedRoute path="/success" component={Success} />
            <AuthenticatedRoute path="/complete_register/:token" component={CompleteRegister} />
            <AuthenticatedRoute path="/verify/:token" component={Verify} />
            <AuthenticatedRoute path="/forgot_password" component={ForgotPassword} />
            <AuthenticatedRoute path="/reset_password/:token" component={ResetPassword} />
            <PrivateRoute exact path="/schedule" component={Schedule} />
            <PrivateRoute path="/schedule/add" component={CreateSchedule} />
            <PrivateRoute path="/schedule/:id/edit" component={EditSchedule} />
            <PrivateRoute path="/schedule/:id/services" component={Services} />
            <PrivateRoute path="/schedule/:id/working_edit" component={WorkingTime} />
            <PrivateRoute path="/schedule/:id/unlock" component={UnlockCalendar} />
            <PrivateRoute path="/schedule/:id/lock" component={CalendarLocks} />
            <PrivateRoute path="/schedule/:id/fixed_reserves" component={FixedReserves} />
            <PrivateRoute path="/password" component={Password} />
            <PrivateRoute path="/worker" component={Home} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/establishments" component={Establishment} />
            <PrivateRoute path="/company_users" component={User} />
            <Route path="/loggout" component={loggout} />
            <RequirementsPrivateRoute path="/requirement/company" component={CreateCompany} />
        </Switch>
        
    </BrowserRouter>
);

export default Routes;