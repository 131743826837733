import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';


export default class ListSchedule extends PureComponent {
  render() {
    return (
        <Grid
        style={{marginTop:20 }}
        container 
        spacing={8}>
            {this.props.children}
        </Grid>
    );
  }
}
