import React, { Component } from 'react';
import Lock from '../Lock/Lock';
import Header from '../Header/Header';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class CalendarLocks extends Component {
  render() {
    if(hasPermission()) return <Redirect to="/" />
    return (
    <div>
        <Header title="Bloqueios" backbutton={this.props.history} />
        <Lock {...this.props} />
    </div>);
  }
}
