import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';

import {format} from 'date-fns';
import { BasePicker, Calendar } from 'material-ui-pickers';
import Schedule from '../Unlock/Schedule';
import { getLock, deleteLock, createLock } from '../../Services/CalendarLock';
import { Grid } from '@material-ui/core';


export default class Lock extends Component {
  state = {
      selectedDate: new Date(),
      schedules: [],
      schedule_id: null
  }

  async componentDidMount() {
    let date, response, schedules, schedule_id;

     date = format(this.state.selectedDate,"yyyy-MM-dd");
     schedule_id = this.props.match.params.id;
     response = await getLock(schedule_id,date);
     schedules = await response.json();

    this.setState({
        schedules,
        schedule_id
    })
    
  }

  changeSchedule = (e,act) => {
    let date_calendar = format(this.state.selectedDate,"yyyy-MM-dd");
    
    if(act) {
       deleteLock(this.state.schedule_id,e, {
        date_calendar
      })
    } else {
      createLock(this.state.schedule_id, {
        start: e,
        end: e,
        date_calendar
      })
    }

    this.setState({
        schedules: this.state.schedules.map(schedule => {
            if(schedule.current === e)
                schedule.active = !schedule.active;
            
            return schedule;
        })
    })
  }

  handleDateChange = async (selectedDate) => {
    let date = format(selectedDate,"yyyy-MM-dd");
    let response = await getLock(this.state.schedule_id,date);
    let schedules = await response.json();
    this.setState({
        selectedDate,
        schedules
    })
  }

  render() {
      let { selectedDate, schedules } = this.state;
      const free = {
        float: "left",
        fontSize: "9pt",
        color: "#000",
        padding: "2px 10px 2px 10px",
        border: "1px solid #000",
        margin: "8px 4px",
        borderRadius: "20px",
      }
  
      const lock = {
          float: "left",
          fontSize: "9pt",
          color: "#fff",
          padding: "2px 10px 2px 10px",
          border: "1px solid #c61f1f",
          margin: "8px 4px",
          borderRadius: "20px",
          background: "#c61f1f"

      }

    return (
    <Grid item xs={12} >
        <BasePicker value={selectedDate} onChange={this.handleDateChange} >
                {({
                  date,
                }) => (
                  <div>
                    <div className="picker">
                      <Paper style={{ overflow: 'hidden' }}>
                        <Calendar disablePast date={date} onChange={this.handleDateChange} />
                      </Paper>
                    </div>
                  </div>
                )}
            </BasePicker> 
            <Schedule toggle={this.changeSchedule} 
                      schedules={schedules} 
                      standard={free}
                      selected={lock}
                      />
      </Grid>  

    );
  }
}
