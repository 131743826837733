import { base_url } from "./Base";

export const get_schedule = async (scheduleid) => {
    return await fetch(`${base_url}schedule/${scheduleid}`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "GET"
    })
}

export const create_schedule = (schedule, working) => {
    return fetch(`${base_url}establishment/${schedule.establishment}/schedule`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "POST",
        body: JSON.stringify({
            id_user_in_company: schedule.worker,
            schedule_interval: schedule.interval,
            notification_time: schedule.notification
        })  
    })
}

export const update_schedule = async (establishmentid, scheduleid, body) => {
    return await fetch(`${base_url}establishment/${establishmentid}/schedule/${scheduleid}` , {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export const delete_schedule = async (establishmentid,scheduleid) => {
    return await fetch(`${base_url}establishment/${establishmentid}/schedule/${scheduleid}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "DELETE",
    })
}

export const list_schedules = (establishmentid, workerid = 0) => {
    return fetch(`${base_url}establishment/${establishmentid}/schedule?worker_id=${workerid}`, {
        headers: {
            "Content-Type": "application/json",
            "Accept" : "application/json",
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "GET"
    })
}