import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';


export default class FormBox extends Component {

  constructor(props) {
    super(props);
    let style =  {
      background: "#363636", 
      padding: "3em .9em .5em .9em",
      marginBottom: ".5em",
      marginTop: "5em"
   };

   this.style = props.style ? props.style : style;

  }


  render() {
    return (
     <Grid 
      container
      spacing={8}
     style={this.style}
     >
      {this.props.children}
     </Grid>
    )
  }
}
