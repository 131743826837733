import React, { Component } from 'react';
import { get_schedule, update_schedule, delete_schedule } from '../../../Services/ScheduleConfig';
import { ScheduleConfigInfo } from '../ScheduleConfigInfo';
import { getUsersFromCompany } from '../../../Services/CompanyUsers';
import { StaticTime } from '../../../Helpers/Time';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { getEstablishmentsFromCompany } from '../../../Services/Establishment';
// import db from '../../../db';

export class ScheduleEdit extends Component {
    _isMounted = false;
    state = {
        establishment: "",
        establishments: [],
        interval: "",
        notification: "",
        worker: "",
        workers: [],
        ready: 0
    };
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.interval = setTimeout;
        
    }

    async componentDidMount() {
        this._isMounted = true;
        let response_schedule = await get_schedule(this.id);
        let json_schedule = await response_schedule.json();
        let response_workers = await getUsersFromCompany(json_schedule.company_id);
        let json_workers = await response_workers.json();
        let response_establishments = await getEstablishmentsFromCompany(json_schedule.company_id);
        let json_establishments = await response_establishments.json();
        if(this._isMounted) {

            this.setState({
                ready: 1,
                interval: json_schedule.time.substring(0,5),
                schedule: json_schedule.schedule_id,
                establishment: json_schedule.establishment_id,
                current_establishment: json_schedule.establishment_id,
                notification: json_schedule.notification_time.substring(0,5),
                worker: json_schedule.user_id,
                workers: json_workers,
                establishments: json_establishments
            }); 
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getInfo = async (data,name) => {
        let value = data;
        clearTimeout(this.interval);

        this.interval = setTimeout(() => {
            this.setState({
                [name]:value.toString(),
                current_establishment: this.state.establishment
            }, async () => {
                let id_establishment = this.state.establishment;
                let id_schedule = this.state.schedule;
                let id_user_in_company = this.state.worker;
                let notification_time = this.state.notification;
                let schedule_interval = this.state.interval;
                let current_establishment = this.state.current_establishment;
    
                    schedule_interval = StaticTime(schedule_interval);
                    notification_time = StaticTime(notification_time);
                let body = {
                    schedule_interval,
                    id_user_in_company,
                    notification_time,
                    id_establishment
                }
    
                await update_schedule(current_establishment,id_schedule,body);
    
            })

        },800)

    }

    delete = async () => {
        let response = await delete_schedule(this.state.establishment,this.state.schedule);
        if(response.status === 200) {
            console.log(this.id);
        }
        // let json = await response.json();
        this.props.history.goBack();
    }

    render() {
        if(!this.state.ready) return '';
        return (
            <Grid style={{margin:10}}>
              
                    <ScheduleConfigInfo 
                        interval={this.state.interval} 
                        notification={this.state.notification} 
                        worker={this.state.worker}
                        workers={this.state.workers}
                        export={this.getInfo}
                        establishments={this.state.establishments}
                        establishment={this.state.establishment}
                    />
                  
                <Button  fullWidth variant="contained" onClick={this.delete} size="small" color="secondary">
                     Excluir
                </Button>
            </Grid>
        )
    }
}