import { base_url } from "./Base";

export const getEstablishmentsFromCompany = async (company_id) => {
    return await fetch(`${base_url}company/${company_id}/establishment`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"GET"
    });
}

export const updateEstablishment = async (company_id, establishment_id, body) => {
    return await fetch(`${base_url}company/${company_id}/establishment/${establishment_id}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json",
            "Authorization" : localStorage.getItem("worker_app_token")
        },
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export const createEstablishment = async (company_id, body) => {
    return await fetch(`${base_url}company/${company_id}/establishment`, {
      headers: {
          "Content-Type": "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("worker_app_token")
      },
      method: "POST",
      body: JSON.stringify(body)
    })
}

export const deleteEstablishment = async(company_id,establishment_id) => {
    return await fetch(`${base_url}company/${company_id}/establishment/${establishment_id}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept" : "application/json",
          "Authorization" : localStorage.getItem("worker_app_token")
        },
        method: "DELETE"
    })
}