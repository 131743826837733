export const TimeMask = (value) => {
    const chars = value.split('');
    const hours = [
        /[0-2]/,
        chars[0] === '2' ? /[0-3]/ : /[0-9]/
    ];

    const minutes = [ /[0-5]/, /[0-9]/ ];
    // const seconds = [/[0-5]/,/[0-9]/];

    return hours.concat(':').concat(minutes);
}