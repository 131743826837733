import React from 'react';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';

const ScheduleItem = (props) => {
    const color = props.signal_color;
    const signal = props.signal ? <span style={{color}}>&#9679;</span> : null;
    const clickItem = () => {
        if(props.onClick) {
            props.onClick();
        }
    }

    return (
        <Grid item xs={6} onClick={clickItem}>
            <Paper style={{ display: "flex", alignItems: "center", margin: "2px 3px", padding:"7px 10px"}}>
                <Typography variant="caption" style={{marginRight: 4}}>
                    {signal}
                    {props.label_info}
                </Typography>
                <Divider style={{width:1, height:28, margin: 4}} />
                <Typography variant="caption"  >
                    {props.label_detail}
                </Typography>
            </Paper>
        </Grid>
    )
}

export default ScheduleItem;