import React, { Component } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import { Validator } from '../../Helpers/Validator';
import { update_profile } from '../../Services/Profile';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import UploadThumb from '../UploadThumb/UploadThumb';


 export default class ProfileForm extends Component {

    constructor(props) {
        super(props);
        const company_user = JSON.parse(localStorage.getItem('current_user'));
        this.state = {
            base64: '',
            alert: false,
            colorAlert: "",
            message: "",
            change: false,
            company_user_id: company_user.id,
            email: company_user.email,
            name: company_user.name,
            current_picture: company_user.picture,
            current_name: company_user.name,
            picture: company_user.picture,
            picture_apresentation: company_user.picture
        };

        this.validator = new Validator();

    }

    submit = async (e) => {
        e.preventDefault();
        let body = {
            name: this.state.name
        }
        
        if(this.state.base64)
            body.base64 = this.state.base64;

        let response = await update_profile(this.state.company_user_id,body);
        let json     = await response.json();
        if(response.status === 200) {
            this.setState({change: false, alert: true, message: json.success, colorAlert: "#41c300" });
            localStorage.setItem('current_user', JSON.stringify(json.user));
        }
    }

    isChanged = () => {
        return this.state.name !== this.state.current_name || this.state.current_picture !== this.state.picture;
    }

    isValid = () => {
        return this.validator.check();
    }

    changeInputs = (input) => (e) => {
        let value = e.target.value;

        this.setState({
            [input]: value
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ alert: false });
    };

    changeImage = (base64) => {
        this.setState({
            base64,
            picture: base64,
            picture_apresentation: base64
        })
    }

    render() {
        this.validator.setValues([
            { value:this.state.name, min: 7, required:true }
        ])
        return (
            <form onSubmit={this.submit} style={{margin: 10}}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                 open={this.state.alert}
                 autoHideDuration={3000}
                 onClose={this.handleClose}
                >
                <SnackbarContent
                  aria-describedby="client-snackbar"
                  message={this.state.message}
                  style={{backgroundColor:this.state.colorAlert}}
                />
        </Snackbar>
            <Grid container justify="center" alignItems="center">
            <UploadThumb changeImage={this.changeImage} logo={this.state.picture_apresentation} />
            </Grid>
                <TextField 
                    id="current_email"
                    label="Email"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    type="email"
                    defaultValue={this.state.email}
                    disabled
                />
                  <TextField 
                    id="name"
                    label="Nome"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    defaultValue={this.state.name}
                    onChange={this.changeInputs('name')}
                    type="text"
                />
                <Button disabled={ !this.isValid() || !this.isChanged()}  style={{marginTop:"1em"}} fullWidth type="submit" variant="contained" color="secondary" >Salvar alterações</Button>
            </form>
        )
    }
}