import React, { Component } from 'react';
import RegisterForm from '../Register/RegisterForm';
export default class Register extends Component {
  render() {
    return (
      <div>
        <RegisterForm {...this.props.history} />
      </div>
     
    )
  }
}
