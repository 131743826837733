import React, { Component } from 'react';
import FormBox from '../Login/FormBox';
import { Title } from './ScheduleConfigInfo';
import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class CheckDays extends Component {


  onChange = (e) => {
    let {checked , value} = e.target;
    let {label, working} = this.props;
    this.props.changeBox({value,label,checked,working});
  }

  shouldComponentUpdate(nextProps) {
    if(this.props.checked === nextProps.checked) return false;
    return true;
  }

  render() {
    return(
    <Grid item xs={6}>
      <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.checked}
                  onChange={this.onChange}
                  value={this.props.value}
                  defaultValue
                />
              }
              label={this.props.label}
      />
     </Grid>
    )
  }
}

export default class ScheduleConfigDays extends Component {
  constructor(props){
    super();
  }
  selectDays = (e) => {
    this.props.export(e);
  }

  render() {
    if(!this.props.show) return null;
    let { days } = this.props;
    return (
     <FormBox
      style={{
        marginTop: "2em", 
        padding: ".4em .9em .5em .9em"
      }}
     >
      <Title title="Dias de funcionamento:" />
      {days.map((day,index) => 
        <CheckDays 
          {...day}
          changeBox={this.selectDays}
          key={index}
        />
      )}
     </FormBox>
    )
  }
}
