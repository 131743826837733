import React, { Component } from 'react';
import { TextField, Button } from '@material-ui/core';
import { Validator } from '../../Helpers/Validator';
import { password } from '../../Auth/Auth';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';


export default class PasswordForm extends Component {

  constructor() {
    super();

    this.state = {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
      message: "",
      alert: false,
      colorAlert: ""
    }

    this.timer = setTimeout;
    this.validator = new Validator();
  }

  timerCallback = (ms,callback) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        callback();
      },ms);  
  }

  changeCurrentPassword = (e) => {
    let current_password = e.target.value;

    this.timerCallback(60,() => {
      this.setState({current_password});
    })
  }

  changeNewPassword = (e) => {
    let new_password = e.target.value;

    this.timerCallback(60, () => {
      this.setState({new_password});
    });
  }

  changeNewPasswordConfirmation = (e) => {
    let new_password_confirmation = e.target.value;

    this.timerCallback(60 , () => {
      this.setState({new_password_confirmation});
    });
  }

  submit = (e) => {
    e.preventDefault();
    let form = e.target;
    password(this.state).then(res => {
      if(res.status === 200) {
       res.json().then(data => {
         form.reset();
        this.setState({
          alert: true,
          message: data.success,
          colorAlert: "#41c300"
        })
       });
      } else if(res.status === 400) {
        res.json().then(data => {
          this.setState({
            alert: true,
            message: data.error,
            colorAlert: "#B00020"
          });
        })
      }
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ alert: false });
  };

  render() {
    this.validator.setValues([
      {value:this.state.current_password, min: 7, required: true},
      {value:this.state.new_password, min: 7, required: true},
      {value:this.state.new_password_confirmation, equals: this.state.new_password}
    ])

    return (
      <form onSubmit={this.submit} className="passwordform">

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.alert}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={this.state.message}
            style={{backgroundColor:this.state.colorAlert}}
          />
        </Snackbar>

         <TextField 
            id="user_current_password"
            label="Senha Atual"
            margin="dense"
            fullWidth
            autoComplete="off"
            type="password"
            onChange={this.changeCurrentPassword}
          />

           <TextField 
            id="user_new_password"
            label="Nova Senha"
            margin="dense"
            fullWidth
            autoComplete="off"
            type="password"
            onChange={this.changeNewPassword}
          />

           <TextField 
            id="user_new_password_confirmation"
            label="Confirmar nova senha"
            margin="dense"
            fullWidth
            autoComplete="off"
            type="password"
            onChange={this.changeNewPasswordConfirmation}
          />

          <Button disabled={!this.validator.check()} style={{marginTop:"1em"}} fullWidth type="submit" variant="contained" color="secondary" >Trocar</Button>
      </form>
    )
  }
}
